import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CompetitionBlock from "../../components/MainStats/CompetitionBlock"
import LastUpdated from "../../components/LastUpdated/LastUpdated"

class CopaEuros extends React.Component {

  render() {

    const mdata = this.props.data.allSheetMessiIntStats.edges
    const rdata = this.props.data.allSheetRonaldoIntStats.edges

    const seasons = ['2024','2021','2019','2016','2015','2012','2011','2008','2007','2004']

    return (
      <Layout>
        <SEO
          title={"Copa America & Euros Stats - Messi vs Ronaldo Goals and Stats"}
          description={`How do Messi and Ronaldo's stats look in their respective international federation competitions, the UEFA European Championships and Copa America?`}
          canonicalPath={`/international-stats/copa-euros-stats/`}
        />

        <h1>
          <span class="sr-only">Messi and Ronaldo</span> Copa America / Euros Stats
        </h1>

        

        <CompetitionBlock competition="Copa America / Euros" subtitle="All Time" type="int" modalSubTitle={`All Time`} mdata={this.props.data.allSheetMessiAllTimeStats.edges} rdata={this.props.data.allSheetRonaldoAllTimeStats.edges} />

        <CompetitionBlock competition="Copa America / Euros Knockouts" type="int" modalSubTitle={`Copa America / Euros KOs`} mdata={this.props.data.allSheetMessiAllTimeStats.edges} rdata={this.props.data.allSheetRonaldoAllTimeStats.edges} />

        { seasons.map(season => (
            <CompetitionBlock key={season} competition="Copa America / Euros" compTitle={`${season}`} type="int" modalSubTitle={`${season}`} mdata={mdata.filter(d => d.node.year === season )} rdata={rdata.filter(d => d.node.year === season )} />
          ))
        }

        <LastUpdated type="Stats" />
          
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    allSheetMessiIntStats(filter: {pgKey: {regex: "/copa-euros/"}}) {
      edges {
        node {
          id
          competition
          year
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
          freeKickAttempts
          bigChancesCreated
          fkc
        }
      }
    }
    allSheetRonaldoIntStats(filter: {pgKey: {regex: "/copa-euros/"}}) {
      edges {
        node {
          id
          competition
          year
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
          freeKickAttempts
          bigChancesCreated
          fkc
        }
      }
    }
    allSheetMessiAllTimeStats(filter: {pgKey: {regex: "/euros-copa/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          freeKickAttempts
          fkc
        }
      }
    }
    allSheetRonaldoAllTimeStats(filter: {pgKey: {regex: "/euros-copa/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          freeKickAttempts
          fkc
        }
      }
    }
  }
`

export default CopaEuros
